import React from 'react'
import { header } from '../styles/single.module.scss'
import PageHeadline from './pageHeadline'

export default function SectionTitle({ text }) {
  return (
    <div className={header}>
      <PageHeadline text={text} />
    </div>
  )
}
