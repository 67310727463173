import React from 'react'
import { useInView } from 'react-intersection-observer'

const HeadingInview = ({ text }) => {
  const words = Array.from(text)

  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

  let animateWords = []
  words.map((val) => (animateWords = [...animateWords, { character: val, delay: getRandomInt(5, 750) }]))

  const { ref, inView } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  return (
    <div className="inviewLetter" ref={ref}>
      {animateWords.map(({ character, delay }, index) => (
        <span key={`${index}-${character}`} style={{ transitionDelay: `${delay}ms`, opacity: inView ? `1` : 0 }}>
          {character}
        </span>
      ))}
    </div>
  )
}
export default HeadingInview
