import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import AboutPageLayout from '../components/aboutPageLayout'

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={data.contentfulPage.title} />
      <AboutPageLayout data={data} />
      <div className="page-width-outline">
        <section className="outline-section outline-mission">
          <h2>ミッション</h2>
          <p className="missionLead">人智を超えた“感動”を未来へ</p>
          <div className="missionText">
            <p>
              私たちは、アンティークコインの安定的な向上が期待できメンテナンスフリーでもある「資産的価値」、そしてコインに詰まった歴史のストーリーや息を呑むほどのデザインの美しさといった「情緒的価値」の両面に注目し、より多くの方々にその魅力を愉しんでいただける世界を目指しています。お客様とは、数十年見越した関係を築かせていただけるよう、誠実かつフレンドリーな接客、コイン情報の透明性と個人情報の厳重な管理を重視して運営しております。
            </p>
          </div>
          <div className="video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1j6fH3Q5-I8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
            ></iframe>
          </div>
        </section>
        <section className="outline-section outline-vision">
          <h2>ビジョン</h2>
          <p>
            世界的なコインプラットフォームを
            <br className="visible-phone" />
            構築する
          </p>
        </section>
        <section className="outline-section outline-action">
          <h2>バリュー</h2>
          <div className="actionWrapper">
            <div className="actionItem">
              <h3>変わらないために変わり続ける</h3>
              <p>ミッションとビジョンの実現のため、常に思考を止めずより良いやり方を探求します。</p>
            </div>
            <div className="actionItem">
              <h3>ちょっとおいでよの精神</h3>
              <p>親しみがあり、安心して話しかけられるような対応を心がけています。</p>
            </div>
            <div className="actionItem">
              <h3>長い目でものをみよう</h3>
              <p>お客様と長期的な関係を築けるよう、どんな時にも誠実なやり取りを心がけます。</p>
            </div>
          </div>
        </section>
        <section className="outline-section outline-member">
          <h2>メンバー</h2>
          <div className="memberWrapper">
            <div className="memberItem for-watanabe">
              <div className="memberImg"></div>
              <h3 className="memberName">
                <span className="sub">店主・代表取締役CEO</span>渡辺孝祐
              </h3>
              <p className="memberText">
                妻と子を愛する2児の父。趣味で始めたアンティークコインに熱中し、2012年にアンティークコインギャラリアを創業する。コインの魅力である「情緒的・文化的な価値」と「資産的な価値」を多くの人に伝えるために、日々活動中。地元、福岡をこよなく愛する。2009年、慶應義塾大学商学部卒。
              </p>
            </div>
            <div className="memberItem for-nakata">
              <div className="memberImg"></div>
              <h3 className="memberName">
                <span className="sub">取締役・最高顧客責任者CCO</span>中田 怜子
              </h3>
              <p className="memberText">
              神戸生まれ、東京育ち。老舗の古銭商、大手地金リサイクル商と経て、2021年よりアンティークコインギャラリアへ。2024年2月取締役就任。多くの方に、美しいマスターピースをお届けするために日々活動を続ける。趣味は料理と旅行、御朱印あつめ。日本近代銀貨研究会準会員。日本ドストエフスキー協会会員。神保町好事家倶楽部会員。翻訳書「ECOINOMICS〜アンティークコイン市場ガイドブック〜」。
              </p>
            </div>
          </div>
        </section>
        <section className="outline-section outline-recruit">
          <h2>採用</h2>
          <p>ビジョンに共感した方、まだまだ手伝ってほしいことがたくさんあります。一度ご連絡お待ちしています！</p>
          <div className="recruitFormBtn">
            <Link to="/recruit">
              <span>採用応募フォームはこちら</span>
            </Link>
          </div>
        </section>
        <section className="outline-section outline-dealer">
          <h2>認定ディーラー</h2>
          <picture className="dealerImg">
            <source srcset="//cdn.shopify.com/s/files/1/0398/9019/0490/t/6/assets/outline_dealer_pcgs-ngc@2x.png" media="(min-width: 750px)" />
            <img src="//cdn.shopify.com/s/files/1/0398/9019/0490/t/6/assets/outline_dealer_pcgs-ngc.png" alt="PCGS NGC" />
          </picture>
          <p className="dealerText">
            私たちは、世界の2大コイン鑑定機関である米国PCGS社およびNGC社の認定ディーラーです。
            <br />
            安心してお買い求めください。
          </p>
        </section>
        <section className="outline-section outline-info">
          <h2>会社情報</h2>
          <table>
            <tbody>
              <tr>
                <th>社名</th>
                <td>株式会社アンティークコインギャラリア</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>105-0001<br />東京都港区虎ノ門3-7-11<br />神谷町アネックス2号館 8階</td>
              </tr>
              <tr>
                <th>代表取締役</th>
                <td>渡辺孝祐</td>
              </tr>
              <tr>
                <th>創業</th>
                <td>2012年5月（法人設立 2015年11月）</td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>10,000,000円</td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td>03-6775-7854</td>
              </tr>
              <tr>
                <th>認定ディーラー</th>
                <td>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.pcgs.com/dealers/details/3387/44">
                    PCGS
                  </a>
                  <br />
                  <a target="_blank" rel="noopener noreferrer" href="https://www.ngccoin.com/coin-dealer-locator/dealer/4742/">
                    NGC
                  </a>
                </td>
              </tr>
              <tr>
                <th>許可登録</th>
                <td>古物商許可証 神奈川県公安委員会第451370013448号<br />＊東京への移転前に取得したため、神奈川県での登録番号となっております。都道府県が変わっても番号に変更はありません。</td>
              </tr>
              <tr>
                <th>顧問弁護士</th>
                <td>ant法律事務所</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "about" }) {
      id
      title
      featuredImage {
        gatsbyImageData
      }
    }
  }
`

export default AboutPage
