// extracted by mini-css-extract-plugin
export var articleBody = "single-module--articleBody--MNKVw";
export var articleFooter = "single-module--articleFooter--oVl6Q";
export var articleForm = "single-module--articleForm--EgO-c";
export var articleFormCheck = "single-module--articleFormCheck--A6Tz5";
export var articleFormHeading = "single-module--articleFormHeading--YtMDT";
export var articleFormWrap = "single-module--articleFormWrap--V41nN";
export var articleHeader = "single-module--articleHeader--z9wD7";
export var articleMetaInfo = "single-module--articleMetaInfo--WypA-";
export var articlePager = "single-module--articlePager--hsl-U";
export var authorName = "single-module--authorName--UsX4p";
export var authorProfile = "single-module--authorProfile--tv3J-";
export var authorProfileImg = "single-module--authorProfileImg--X-zCF";
export var banner = "single-module--banner--qdQRc";
export var container = "single-module--container--uGiIj";
export var header = "single-module--header--76TQ1";
export var hsRichtext = "single-module--hs-richtext--GvqwN";
export var lettersSubContainer = "single-module--lettersSubContainer--RP6ry";
export var narrowsinglePageContainer = "single-module--narrowsinglePageContainer--hu0Ch";
export var next = "single-module--next--82cvY";
export var prev = "single-module--prev--RnfmN";
export var sectionTitle = "single-module--sectionTitle--yl3Jk";
export var singleArticle = "single-module--singleArticle--lh8bP";
export var singlePageContainer = "single-module--singlePageContainer--4oBdt";
export var staticArticle = "single-module--staticArticle--JuN-K";
export var subPageMv = "single-module--subPageMv--gHJb1";
export var tagNames = "single-module--tagNames--nqCAb";