import React from 'react'
import HeadingInview from './module/headingInview'

export default function PageHeadline({ text }) {
  return (
    <h2 className="heading with-ornament">
      <HeadingInview text={text} />
    </h2>
  )
}
